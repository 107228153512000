import React from "react"
import { css } from "@emotion/core"

export default ({ children, styles, visible }) => {
  styles = [
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: var(--base3);
      box-shadow: 0 0 5px #fcfcfc;
      display: ${visible ? "flex" : "none"};
      flex-direction: column;
      align-items: center;
    `,
    styles,
  ]

  return (
    <section className="bar" css={styles}>
      {children}
    </section>
  )
}
