import React, { useState } from "react"
import { Global, css } from "@emotion/core"

import Bar from "../parts/bar"
import Footer from "../parts/footer"
import globalStyles from "../../utils/global-styles"
import Player from "../parts/player"
import { rhythm } from "../../utils/typography"
import SEO from "../seo"
import imgae from "../../images/shushi.jpg"

const styles = css`
  .contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    margin: 0 auto;
    max-width: 40rem;
  }
  main {
    margin-top: 4rem;
    padding: 0 ${rhythm(1)};
    flex: 0 0 auto;
  }
`

export default ({ children, title, audio }) => {
  const [playerVisible, setPlayerVisible] = useState(true)

  const togglePlayerVisible = e => {
    if (playerVisible) {
      setPlayerVisible(false)
    } else {
      setPlayerVisible(true)
    }
  }

  return (
    <div css={styles}>
      <Global styles={globalStyles} />
      <SEO title={title} />
      <Bar visible={playerVisible}>
        <div
          css={css`
            padding: 1.5rem 0.5rem;
            width: 300px;
          `}
        >
          <Player src={audio} />
        </div>
      </Bar>
      <div onClick={togglePlayerVisible}>
        <div class="contents">
          <main>{children}</main>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}
